<template>
  <v-card flat class="fill-height box">
    <v-app-bar flat class="row header mx-0">
      <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
      <v-divider class="mx-3" inset vertical></v-divider>
      <span class="headline">Rental Shop</span>

    </v-app-bar>
    <v-card-text class="row content mx-0">
      <v-row align-content="center" justify="center">
        <v-col cols="10" md="8" lg="6">
          <div class="headline pl-0">{{ title }}</div>
          <v-card-subtitle class="pl-0 pt-0">{{ subtitle }}</v-card-subtitle>

          <v-text-field v-model="phone" outlined placeholder="Phone number" :error-messages="phoneError" v-mask="'(###) ###-####'" @keypress.enter="nextStep"></v-text-field>
        </v-col>
          
      </v-row>
      <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep" :loading="loading"> <!-- :disabled="phone.length < 10" -->
        <v-icon left>mdi-arrow-right</v-icon> Next
      </v-btn>

    </v-card-text>
  </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
  props: {
    workflow: { type: String, default: 'rent' }
  },
  data() {
    return {
      phone: '',
      phoneError: '',

      loading: false,
    }
  },
  computed: {
    header() {
      return 'New Rent';
    },
    title() {
      return 'Enter your phone number';
    },
    subtitle() {
      return 'Please provide your phone number to continue';
    }
  },
  mounted() {
    // if( !this.isTokenValid() )
    //   this.$router.push( '/unauthorized' );
  },
  methods: {
    ...mapActions({
      setCustomer   : 'setCustomer',
    }),
    isValid() {
      let errorFound = false;
      this.phoneError = '';

      if( !/^(\d{1}|)(-|\s|)(\(|)[2-9]\d{2}(\)\s|-|\s)\d{3}-\d{4}$/g.test( this.phone ) ) {
        this.phoneError = 'must be a valid phone number';
        errorFound = true;
      }

      return !errorFound
    },
    async nextStep() {
      if( !this.isValid() )
        return;

      try {
        this.$store.dispatch( 'setCustomerProp', { key: 'phone', value: this.phone });

        // get customer information
        let api = new API();
        this.loading = true;
        let res = await api.get('/customer-info/' + this.phone);
        this.loading = false;
            
        if( res.data.status !== true ) {
          this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning');
          return;
        }

        let c = res.data.data;
        
        this.setCustomer({ 
          id              : c.id,
          phone           : this.phone,
          firstName       : c.firstName,
          lastName        : c.lastName,
          email           : c.email,
          birthdate       : c.birthdate,
          gender          : c.gender,
          otherId         : c.otherId,
          addressLine1    : c.addressLine1,
          addressLine2    : c.addressLine2,
          addressCity     : c.addressCity,
          addressState    : c.addressState,
          addressZipcode  : c.addressZipcode,
        });

        this.$router.push( '/customer-details' );
        this.phone = '';
      }
      catch(error) {
        this.loading = false;
        this.mxShowMessage( 'Error', error, 'error' );
      }
    },
  },
  directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}
</style>